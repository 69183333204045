<template>
    <div id="sales-term">
        <h1>CONDITIONS GENERALES DE VENTES WWW.TWOTRAINSLEFT.COM </h1>
        <p>Les présentes conditions de vente sont conclues d’une part par l’association TWO TRAINS
        LEFT dont le siège social est situé à GOUVIEUX immatriculée au Registre du Commerce et
        des Sociétés de BEAUVAIS sous le numéro 85160017100017 ci-après dénommée "TWO
        TRAINS LEFT" et gérant le site www.twotrainsleft.com et, d’autre part, par toute personne
        physique ou morale souhaitant procéder à un achat via le site internet www.twotrainsleft.com
            dénommée ci-après " l’acheteur ".</p>
        <h2>Article 1. Objet</h2>
        <p>Les présentes conditions de vente visent à définir les relations contractuelles entre TWO
        TRAINS LEFT et l’acheteur et les conditions applicables à tout achat effectué par le biais du
        site internet www.twotrainsleft.com. L’acquisition d’un produit à travers le présent site
        implique une acceptation sans réserve par l’acheteur des présentes conditions de vente dont
        l’acheteur reconnaît avoir pris connaissance préalablement à sa commande. Avant toute
        transaction, l’acheteur déclare d’une part que l’achat de produits sur le site
        www.twotrainsleft.com est sans rapport direct avec son activité professionnelle et est limité à
        une utilisation strictement personnelle et d’autre part avoir la pleine capacité juridique, lui
        permettant de s’engager au titre des présentes conditions générales de ventes.
        La société TWO TRAINS LEFT conserve la possibilité de modifier à tout moment ces
        conditions de ventes, afin de respecter toute nouvelle réglementation ou dans le but
        d'améliorer l’utilisation de son site. De ce fait, les conditions applicables seront celles en
        vigueur à la date de la commande par l’acheteur.</p>
        <h2>Article 2. Produits</h2>
        <p>Les produits proposés sont ceux qui figurent sur le site www.twotrainsleft.com de la société
        TWO TRAINS LEFT, dans la limite des stocks disponibles. L’association TWO TRAINS
        LEFT se réserve le droit de modifier à tout moment l’assortiment de produits. Chaque produit
        est présenté sur le site internet sous forme d’un descriptif reprenant ses principales
        caractéristiques techniques (contenance, utilisation, composition…). Les photographies sont
        les plus fidèles possibles mais n’engagent en rien le Vendeur. La vente des produits présentés
        dans le site www.twotrainsleft.com est destinée à tous les acheteurs résidants dans les pays
            qui autorisent pleinement l’entrée sur leur territoire de ces produits.</p>
        <h2>Article 3. Tarifs</h2>
        <p>Les prix figurant sur les fiches produits du catalogue internet sont des prix en Euros (€), la
        TVA est non applicable, l’Association TWO TRAINS LEFT étant exonérée des impôts
        commerciaux. L’association TWO TRAINS LEFT se réserve le droit de modifier ses prix à
        tout moment, étant toutefois entendu que le prix figurant au catalogue le jour de la commande
        sera le seul applicable à l’acheteur. Les prix indiqués ne comprennent pas les frais de
        livraison, facturés en supplément du prix des produits achetés suivant le montant total de la
        commande. En France métropolitaine, pour toute commande, un forfait de participation aux
        frais d’expédition sera facturé à l’acheteur d'un montant de 5€. Pour toutes commandes
        2
        extérieures à la France métropolitaine, un forfait de participation aux frais d’expédition sera
            facturé à l’acheteur d'un montant de 8€.</p>
        <h2>Article 4. Commande et modalités de paiement</h2>
        <p>L’association TWO TRAINS LEFT propose à l’acheteur de commander et régler ses produits
            en plusieurs étapes, avec 3 options de paiement au choix :</p>
        <p>- Paiement sécurisé par Paypal ou carte bancaire (via le système PAYPAL) : l’acheteur
        sélectionne les produits qu’il souhaite commander dans le « panier », modifie si besoin
        (quantités, références…), vérifie l’adresse de livraison ou en renseigne une nouvelle. Puis, les
        frais de port sont calculés et soumis à l’acheteur, ainsi que le nom du transporteur. Ensuite,
        l’acheteur choisit le mode de paiement de son choix : « Paiement par Paypal ». L’étape
        suivante lui propose de vérifier l’ensemble des informations, prendre connaissance et accepter
        les présentes conditions générales de vente en cochant la case correspondante, puis l’invite à
        valider sa commande en cliquant sur le bouton « Confirmer ma commande ». Enfin,
        l’acheteur est redirigé sur l’interface sécurisée PAYPAL afin de renseigner en toute sécurité
        ses références de compte Paypal ou de carte bleue personnelle. Si le paiement est accepté, la
        commande est enregistrée et le contrat définitivement formé. Le paiement par compte Paypal
        ou par carte bancaire est irrévocable. En cas d’utilisation frauduleuse de celle-ci, l’acheteur
        pourra exiger l’annulation du paiement par carte, les sommes versées seront alors recréditées
        ou restituées. La responsabilité du titulaire d’une carte bancaire n’est pas engagée si le
        paiement contesté a été prouvé effectué frauduleusement, à distance, sans utilisation physique
        de sa carte. Pour obtenir le remboursement du débit frauduleux et des éventuels frais
        bancaires que l’opération a pu engendrer, le porteur de la carte doit contester, par écrit, le
        prélèvement auprès de sa banque, dans les 70 jours suivant l’opération, voire 120 jours si le
        contrat le liant à celle-ci le prévoit. Les montants prélevés sont remboursés par la banque dans
        un délai maximum d’un mois après réception de la contestation écrite formée par le porteur.
        Aucun frais de restitution des sommes ne pourra être mis à la charge du titulaire.
        La confirmation d’une commande entraîne acceptation des présentes conditions de vente, la
        reconnaissance d’en avoir parfaite connaissance et la renonciation à se prévaloir de ses
        propres conditions d’achat. L’ensemble des données fournies et la confirmation enregistrée
        vaudront preuve de la transaction. Si l’acheteur possède une adresse électronique et s’il l’a
        renseignée sur son bon de commande, l’association TWO TRAINS LEFT lui communiquera
        par courrier électronique la confirmation de l’enregistrement de sa commande.
        Si l’acheteur souhaite contacter la société TWO TRAINS LEFT il peut le faire soit par email
            à l’adresse suivante : twotrainsleftmerch@gmail.com.</p>
        <h2>Article 5. Réserve de propriété</h2>
        <p>L’association TWO TRAINS LEFT conserve la propriété pleine et entière des produits
            vendus jusqu'au parfait encaissement du prix, en principal, frais et taxes compris.</p>
        <h2>Article 6. Rétractation</h2>
        <p>En vertu de l’article L121-20 du Code de la consommation, l’acheteur dispose d'un délai de
        quatorze jours ouvrables à compter de la livraison de leur commande pour exercer son droit
        de rétractation et ainsi faire retour du produit au vendeur pour échange ou remboursement
            sans pénalité, à l’exception des frais de retour.</p>
        <h2>Article 7. Livraison</h2>
        <p>Les livraisons sont faites à l’adresse indiquée sur le bon de commande qui ne peut être que
        dans la zone géographique convenue. Les commandes sont effectuées par La Poste via
        COLISSIMO, service de livraison avec suivi, remise sans signature. Les délais de livraison ne
        sont donnés qu’à titre indicatif ; si ceux-ci dépassent trente jours à compter de la commande,
        le contrat de vente pourra être résilié et l’acheteur remboursé. L’association TWO TRAINS
        LEFT pourra fournir par e-mail à l’acheteur le numéro de suivi de son colis. L’acheteur est
        livré à son domicile par son facteur. En cas d’absence de l’acheteur, il recevra un avis de
        3
        passage de son facteur, ce qui lui permet de retirer les produits commandés au bureau de Poste
        le plus proche, pendant un délai indiqué par les services postaux. Les risques liés au transport
        sont à la charge de l'acquéreur à compter du moment où les articles quittent les locaux de
        l’association TWO TRAINS LEFT. L’acheteur est tenu de vérifier en présence du préposé de
        La Poste ou du livreur, l’état de l’emballage de la marchandise et son contenu à la livraison.
        En cas de dommage pendant le transport, toute protestation doit être effectuée auprès du
            transporteur dans un délai de trois jours à compter de la livraison.</p>
        <h2>Article 8. Garantie</h2>
        <p>Tous les produits fournis par l’association bénéficient de la garantie légale prévue par les
        articles 1641 et suivants du Code civil. En cas de non conformité d’un produit vendu, il
        pourra être retourné à l’association TWO TRAINS LEFT qui le reprendra, l’échangera ou le
        remboursera. Toutes les réclamations, demandes d’échange ou de remboursement doivent
        s’effectuer par voie électronique à l’adresse suivante : twotrainsleftmerch@gmail.com, dans
            un délai de trente jours après livraison.</p>
        <h2>Article 9. Responsabilité</h2>
        <p>L’association TWO TRAINS LEFT dans le processus de vente à distance, n’est tenue que par
        une obligation de moyens. Sa responsabilité ne pourra être engagée pour un dommage
        résultant de l’utilisation du réseau Internet tel que perte de données, intrusion, virus, rupture
            du service, ou autres problèmes involontaires.</p>
        <h2>Article 10. Propriété intellectuelle</h2>
        <p>Tous les éléments du site www.twotrainsleft.com sont et restent la propriété intellectuelle et
        exclusive de l’association TWO TRAINS LEFT. Personne n’est autorisé à reproduire,
        exploiter, ou utiliser à quelque titre que ce soit, même partiellement, des éléments du site
            qu’ils soient sous forme de photo, logo, visuel ou texte.</p>
        <h2>Article 11. Données à caractère personnel</h2>
        <p>L’association TWO TRAINS LEFT s'engage à préserver la confidentialité des informations
        fournies par l’acheteur, qu'il serait amené à transmettre pour l'utilisation de certains services.
        Toute information le concernant est soumise aux dispositions de la loi n° 78-17 du 6 janvier
        1978. A ce titre, l'internaute dispose d'un droit d'accès, de modification et de suppression des
        informations le concernant. Il peut en faire la demande à tout moment par courrier
            électronique à l’adresse suivante : twotrainsleftmerch@gmail.com.</p>
        <h2>Article 12. Règlement des litiges</h2>
        <p>Les présentes conditions de vente à distance sont soumises à la loi française. Pour tous litiges
            ou contentieux, le Tribunal compétent sera celui de BEAUVAIS.</p>
    </div>
</template>

<script>
    export default {
        name: "TermOfsales"
    }
</script>

<style scoped>
    @import url('../style/sales-term..css');
</style>
