<template>
    <div id="app">
        <nav-bar :client-data="clientData" :config-data="configData"></nav-bar>
        <router-view @updatePerson="updatePerson"
                     @updateAddress="updateAddress"
                     @deleteArticle="deleteArticle"
                     @updateNumbers="updateNumbers"
                     @addProductToCart="addProductToCart"
                     @paymentConfirmed="resetClientData"
                     @memorizeStep="memorizeStep"
                     :client-data="clientData" :config-data="configData">
        </router-view>
        <div id="flammes"></div>
        <Footer :config-data="configData"></Footer>
    </div>
</template>

<script>
    import NavBar from "@/components/NavBar";
    import Footer from "@/components/Footer";
    import {configDataJS} from "./data/data.js";

    export default {
        name: 'App',
        components: {NavBar,Footer},
        data() {
            return {
                clientData: {
                    person: {},
                    address: {},
                    transportFees: 0,
                    articlesList: [],
                    articlesNumber: 0,
                    articlesPrice: 0,
                    step: 1,
                    hideLogo: false,
                },
                configData: configDataJS
            }
        },
        created: function() {

        },
        methods: {
            addProductToCart(value) {
                const result = this.clientData.articlesList.findIndex(product =>
                    product.product.id === value.product.id && product.option === value.option
                );
                console.log(result);
                if (result === -1) {
                    this.clientData.articlesList.push(value);
                    this.clientData.articlesNumber += 1;
                    this.clientData.articlesPrice += value.product.price;
                } else {
                    this.clientData.articlesList[result].number++;
                    this.updateNumbers();
                }
            },
            updateNumbers() {
                var newArticlesNumber = 0;
                var newArticlesPrice = 0;
                this.clientData.articlesList.forEach(product => {
                    newArticlesPrice += product.number*product.product.price;
                    newArticlesNumber += product.number;
                });
                this.clientData.articlesPrice = newArticlesPrice;
                this.clientData.articlesNumber = newArticlesNumber;
            },
            deleteArticle(value) {
               this.clientData.articlesList.splice(value,1);
               this.updateNumbers();
            },
            updatePerson(value) {
                this.clientData.person = value;
            },
            updateAddress(value) {
                this.clientData.address = value;
                this.computeTransportFees();
            },
            resetClientData() {
                this.clientData.articlesList = [];
                this.clientData.articlesNumber = 0;
                this.clientData.articlesPrice = 0;
            },
            computeTransportFees() {
                if (this.clientData.address.country === "France") {
                    this.clientData.transportFees = 8;
                } else {
                    this.clientData.transportFees = 13;
                }
            },
            memorizeStep (value) {
                this.clientData.step = value;
            }
        }
    }
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap');
</style>
