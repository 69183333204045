<template>
    <div id="footer">
        <div>
            <a id="aboutlink" v-on:click="linkClicked($event)" :route="configData.about.route">about</a>
            <a id="saleslink" v-on:click="linkClicked($event)" :route="configData.sales.route"> terms of sales</a>
        </div>
        <div>
            <a href="https://twitter.com/twotrainsleft?lang=fr" target="_blank">
                <img src="../assets/img/TWITTER.png">
            </a>
            <a href="https://www.youtube.com/channel/UC1YPv2eKAEvbU48pKEgi0bw" target="_blank">
                <img src="../assets/img/YT.png">
            </a>
            <a href="https://fr-fr.facebook.com/twotrainsleft/" target="_blank">
                <img src="../assets/img/FB.png">
            </a>
            <a href="https://www.instagram.com/twotrainsleft/?hl=fr" target="_blank">
                <img src="../assets/img/INSTA.png">
            </a>
        </div>

    </div>
</template>

<script>
    import {linkClicked} from '@/services/routingService';
    export default {
        name: "Footer",
        props: ['configData'],
        methods: {
            linkClicked,
        }
    }
</script>

<style scoped>
    @import "../style/footer.css";
</style>
