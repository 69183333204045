<template>
    <div id="navbar">
        <div class="start">
            <img v-if="!clientData.hideLogo" v-on:click="linkClicked($event)" route="/" id="logo" :src="configData.visuals.logoWhite" height="100px">
        </div>
        <div class="navlinks mid">
            <a v-for="option in this.configData.options" @click="linkClicked($event)" :route="option.route" :key="option.name" :id="option.name" class="navLink">{{option.name}}</a>
        </div>
        <div class="end">
            <div id="navShop" v-on:click="linkClicked($event)" :route="configData.cart.route">
                <svg id="shopcart" class="logo" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" >
                    <path d="M15.202 0h-14.188c-.561 0-1.014.4-1.014 1s.453 1 1 1h1v9c0 .6.5 1 1 1h10c.56 0 1-.447 1-1s-.44-1-1-1h-9v-2h9.175c.56 0 1.124-.435 1.259-.97l1.536-6.06c.136-.57-.17-.97-.77-.97zm-2.596 6h-8.606v-4h9.397l-.791 4z"></path>
                    <circle cx="4.5" cy="14.5" r="1.5"></circle>
                    <circle cx="11.5" cy="14.5" r="1.5"></circle>
                </svg>
                <a id="cartinfos" v-on:click="linkClicked($event)" :route="configData.cart.route">{{clientData.articlesNumber}} / € {{clientData.articlesPrice}}</a>
            </div>
            <button v-on:click="linkClicked($event)"
                    :route="this.configData.menuOption.route" id="toggle">
                <svg class="logo" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16 10">
                    <path d="M15.5 0h-15c-.276 0-.5.2-.5.5v1c0 .3.2.5.5.5h15c.276 0 .5-.2.5-.5v-1c0-.3-.2-.5-.5-.5zM15.5 4h-15c-.276 0-.5.2-.5.5v1c0 .3.2.5.5.5h15c.276 0 .5-.2.5-.5v-1c0-.3-.2-.5-.5-.5zM15.5 8h-15c-.276 0-.5.2-.5.5v1c0 .3.2.5.5.5h15c.276 0 .5-.224.5-.5v-1c0-.3-.2-.5-.5-.5z"></path>
                </svg>
            </button>
        </div>
    </div>

</template>

<script>
    import {linkClicked} from '@/services/routingService';
    export default {
        name: "NavBar",
        props: ['clientData','configData'],
        data() {
          return {
          }
        },
        methods: {
            linkClicked
        }
    }
</script>

<style scoped>
    @import "../style/navBar.css";
</style>
