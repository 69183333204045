export function linkClicked(event) {
    var route = document.getElementById(event.target.id).getAttribute('route');
    console.log(event.target.id)
    if(route === this.configData.menuOption.route) {
        document.getElementById("toggle").setAttribute('route',"/");
    } else if (route !== this.configData.menuOption.route && event.target.id==="toggle") {
        document.getElementById("toggle").setAttribute('route',this.configData.menuOption.route);
    }
    this.$router.push(route);
}
