<template>
    <FormulateForm id="address" v-model="address" @submit="goToPayment">
        <h2 class="stepTitle">Shipping</h2>
        <FormulateInput type="text" class="bigInput" name="address" label="Address" validation="required"/>
        <FormulateInput type="text" class="bigInput" name="cplAddress" label="Apt num, Suite (optional)" validation="optional"/>
        <div class="groupByTwo">
            <FormulateInput
                    @change="this.updateStates"
                    :options=this.countries
                    type="select"
                    v-model="address.country"
                    name="country"
                    placeholder="Select a country"
                    label="Country"
                    validation="required"
            />
            <FormulateInput
                    :options=this.states
                    type="select"
                    name="state"
                    label="State"
                    validation="required"
            />
        </div>
        <div class="groupByTwo">
            <FormulateInput type="text" name="city" label="City" validation="required"/>
            <FormulateInput type="text" name="zip" label="ZIP" validation="required"/>
        </div>
        <div class="submit-section">
            <button @click="previousStep" type="button" id="previousStep" class="btn btn-secondary">Previous Step</button>
            <button type="submit" class="btn btn-secondary">Next Step 2/2</button>
        </div>
    </FormulateForm>
</template>

<script>
    export default {
        name: "AddressForm",
        props: ['personAddress','configData'],
        data() {
            return {
                address: {},
                countrySelected: "",
                countries: [],
                states: [],
            }
        },
        mounted: function () {
            this.address = this.personAddress;
            this.configData.countries.forEach(country => {
                this.countries.push(country.country);
            });
            if (this.address.country) {
                this.updateStates();
            }
        },
        methods: {
            updateStates() {
                this.states = this.configData.countries.find(country => country.country === this.address.country).states;
            },
            previousStep() {
                this.$emit("previousStep",this.address);
            },
            goToPayment() {
                this.$emit("goToPayment",this.address);
            }
        }
    }
</script>

<style scoped>
</style>
