<template>
    <div id="about">
        <h1>TWO TRAINS LEFT</h1>
        <p>Association Loi 1901 enregistrée sous le N° W604008389 à la préfecture de l’Oise.</p>
        <p>N° Siret 85160017100017</p>
        <p>Catégorie juridique : 9001Z Association déclarée - twotrainsleft@gmail.com.</p>
        <p>TVA non applicable car association exonérée des impôts commerciaux.</p>
    </div>
</template>

<script>
    export default {
        name: "about"
    }
</script>

<style scoped>
    @import "../style/about.css";
</style>
