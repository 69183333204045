<template>
    <FormulateForm @submit="nextStep" id="person" v-model="person">
        <h2 class="stepTitle">Contact Informations</h2>
        <div class="groupByTwo">
            <FormulateInput type="text" name="firstName" label="First name " validation="required"/>
            <FormulateInput type="text" name="lastName" label="Last name" validation="required"/>
        </div>
        <FormulateInput type="text" class="bigInput" name="email" label="Email address" validation="required|email"/>
        <FormulateInput type="text" class="bigInput" name="phone" label="Phone (optional)" validation="optional|number"/>
        <div class="submit-section">
            <button type="submit" class="btn btn-secondary">Next Step 1/2</button>
        </div>
    </FormulateForm>
</template>

<script>
    export default {
        name: "ContactForm",
        props: ['personInfo'],
        data() {
            return {
                person: {},
            }
        },
        mounted: function () {
          this.person = this.personInfo;
        },
        methods: {
            nextStep() {
                this.$emit("nextStep",this.person);
            }
        }
    }
</script>

<style scoped>
</style>
