<template>
    <div id="product">
        <div class="productDescription">
            <div class="leftPart">
                <img :src=this.product.imgURL>
            </div>
            <div class="rightPart">
                <div class="title">
                    <h1 class="productName">{{product.name}}</h1>
                    <p v-if="product.price" class="productPrice">{{product.price}} €</p>
                </div>
                <pre id="description">{{this.product.product_description}}</pre>
                <div v-if="error">
                    <b>Please correct the following error:</b>
                    <ul>
                        <li>{{ error }}</li>
                    </ul>
                </div>
                <select v-if="this.product.options" v-model="option" class="form-control form-control-lg">
                    <option selected disabled>Select Option</option>
                    <option v-for="option in this.product.options" :key="option.name">{{option.name}}</option>
                </select>
                <button v-if="product.price && product.id != 1" @click="checkForm" type="submit" class="btn btn-secondary">ORDER</button>
                <button v-if="product.id == 1" @click="checkForm" type="submit" class="btn btn-secondary">ORDER</button>
                <button v-if="product.id == 1" @click="goToStream" type="submit" class="btn btn-secondary">STREAM</button>
            </div>
        </div>
    </div>
</template>

<script>
    import {displayNavbar, navbarToWhiteMod} from "@/services/styleChanges";

    export default {
        name: "Product",
        props: ['configData', 'clientData'],
        data() {
            return {
                product: {},
                option: "Select Option",
                error: null
            }
        },
        mounted: function () {
            displayNavbar();
            navbarToWhiteMod();
            this.product = this.configData.products.list.find(productIterated => productIterated.id == this.$route.params.productID);
            if (!this.product.options) {
                this.option = "default";
            }
            document.getElementById('navbar').setAttribute('class', "whiteMod");
        },
        methods: {
            checkForm() {
                this.error = null;
                if (this.option === "Select Option") {
                    this.error = "select an option";
                }
                if (this.error == null) {
                    this.addProductToCart();
                }
            },
            addProductToCart() {
                this.$emit("addProductToCart", {product: this.product, option: this.option, number: 1});
                this.$router.push("/cart");
            },
            goToStream() {
                window.open("https://bit.ly/3zfRB1y");
            }
        }
    }
</script>

<style scoped>
    @import "../style/product.css";
</style>
