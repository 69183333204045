<template>
    <div id="menuoptions">
        <ul id="options">
            <li v-for="option in this.configData.options" :key="option.name" class="option">
                <a @click="linkClicked($event)" :route=option.route :id=option.name class="navLink">{{option.name}}</a>
            </li>
        </ul>
    </div>
</template>

<script>
    import {linkClicked} from '@/services/routingService';
    import  {navbarToDefault} from "@/services/styleChanges";

    export default {
        name: "MenuOptions",
        props: ['configData'],
        mounted: function () {
            navbarToDefault();
        },
        methods: {
            linkClicked
        }
    }
</script>

<style scoped>
    @import "../style/menuOptions.css";
    @import "../style/navBar.css";
</style>
