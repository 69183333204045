import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import jQuery from 'jquery';
import {configDataJS} from "./data/data.js";
global.jquery = jQuery;
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue';
import VueFormulate from '@braid/vue-formulate';
import 'bootstrap';
import '@/style/app.css';
import HomePage from "@/components/HomePage";
import MenuOptions from "@/components/MenuOptions";
import Products from "@/components/Products";
import Product from "@/components/Product";
import Cart from "@/components/Cart";
import Payment from "@/components/Payment";
import OrderForm from "@/components/OrderForm";
import VueSimpleAlert from "vue-simple-alert";
import Contact from "@/components/Contact";
import About from "@/components/About";
import TermOfSales from "@/components/TermOfSales";


Vue.config.productionTip = false
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSimpleAlert);
Vue.use(VueRouter);
Vue.use(VueFormulate);



const routes = [
  {path : '/', component : HomePage},
  {path : configDataJS.menuOption.route, component : MenuOptions, props:true},
  {path : configDataJS.products.route, component : Products, props: true},
  {path : configDataJS.product.route + ":productID", component : Product, props: true},
  {path : configDataJS.cart.route, component : Cart, props: true},
  {path : configDataJS.payment.route, component : Payment, props: true},
  {path : configDataJS.contact.route, component : Contact, props: true},
  {path : configDataJS.orderForm.route, component : OrderForm, props: true},
  {path : configDataJS.about.route, component : About, props: true},
  {path : configDataJS.sales.route, component : TermOfSales, props: true},
  {path: '*', redirect : '/'},
];

const router = new VueRouter ({mode: "history",routes, scrollBehavior () {
    return { x: 0, y: 0 }
  }} )

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
