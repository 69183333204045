import {configDataJS} from "@/data/data";

export function hideNavbar() {
    document.getElementById('navbar').style.display = "none";
}
export function displayNavbar() {
    document.getElementById('navbar').style.display = "";
}
export function navbarToWhiteMod() {
    document.getElementById('navbar').setAttribute('class', "whiteMod");
    document.getElementById('logo').setAttribute('src', configDataJS.visuals.logoDark);
}
export function navbarToDefault() {
    document.getElementById('navbar').setAttribute('class',"default");
    document.getElementById('logo').setAttribute('src', configDataJS.visuals.logoWhite);
}
export function fitProductsToHomePage() {
    document.getElementById('products').style.paddingTop = "0px";
}
