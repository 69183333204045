<template>
    <div id="payment">
        <div id="mask" v-if="paidFor"></div>
        <div class="lds-ring" v-if="!loaded">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div id="paypal-container" ref="paypal">
            <p class="note" v-if="loaded">* If your billing address is different from your shipping address, please
                click on the paypal button and select the card payment option</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Payment",
        props: ['clientData', 'configData'],
        data: function () {
            return {
                loaded: false,
                paidFor: false,
                country_code: "FR",
                currency_code: "EUR",
                delivrer: "La Poste",
                items: []
            };
        },
        mounted: function () {
            const script = document.createElement("script");
            script.src =
                "https://www.paypal.com/sdk/js?client-id=" + process.env.VUE_APP_ENV_PAYPAL + "&currency=EUR";
            script.addEventListener("load", this.setLoaded);
            document.body.appendChild(script);
            this.country_code = this.configData.countryCodes.find(country => this.clientData.address.country === country.country_name).country_code;
            this.clientData.articlesList.forEach(article => {
                this.items.push(
                    {
                        name: article.product.name + " " + article.option,
                        unit_amount: {
                            currency_code: this.currency_code,
                            value: article.product.price,
                        },
                        quantity: article.number,
                    }
                );
            });
        },
        methods: {
            setLoaded: function () {
                this.loaded = true;
                window.paypal
                    .Buttons({
                        env: 'live',
                        style: {
                            layout: 'vertical',
                            color: 'black',
                            shape: 'rect',
                            label: 'paypal',
                            size: 'responsive'
                        },
                        createOrder: (data, actions) => {
                            return actions.order.create({
                                payer: {
                                    email_address: this.clientData.person.email,
                                    name: {
                                        given_name: this.clientData.person.firstName,
                                        surname: this.clientData.person.lastName
                                    },
                                    address: {
                                        address_line_1: this.clientData.address.address,
                                        address_line_2: this.clientData.address.cplAddress,
                                        admin_area_2: this.clientData.address.city,
                                        admin_area_1: this.clientData.address.state,
                                        postal_code: this.clientData.address.zip,
                                        country_code: this.country_code
                                    }
                                },
                                purchase_units: [
                                    {
                                        description: "Your Two Trains Left order",
                                        amount: {
                                            currency_code: this.currency_code,
                                            value: this.clientData.articlesPrice + this.clientData.transportFees,
                                            breakdown: {
                                                item_total: {
                                                    currency_code: this.currency_code,
                                                    value: this.clientData.articlesPrice,
                                                },
                                                shipping: {
                                                    currency_code: this.currency_code,
                                                    value: this.clientData.transportFees
                                                },
                                            }
                                        },
                                        items: this.items,
                                        shipping: {
                                            name: {
                                                full_name: this.clientData.person.firstName + " " + this.clientData.person.lastName,
                                            },
                                            method: this.delivrer,
                                            address: {
                                                address_line_1: this.clientData.address.address,
                                                address_line_2: this.clientData.address.cplAddress,
                                                admin_area_2: this.clientData.address.city,
                                                admin_area_1: this.clientData.address.state,
                                                postal_code: this.clientData.address.zip,
                                                country_code: this.country_code
                                            }
                                        },
                                    }
                                ]
                            });
                        },
                        onShippingChange: function(data,actions){
                            return actions.resolve();
                        },
                        onApprove: async (data, actions) => {
                            this.paidFor = true;
                            const order = await actions.order.capture();
                            this.paidFor = false;
                            this.$emit("paymentCompleted", order);
                        },
                        onError: err => {
                            console.log(err);
                        }
                    })
                    .render(this.$refs.paypal);
            },
        }
    };
</script>
<style scoped>
    @import "../style/payment.css";
</style>
