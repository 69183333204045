<template>
    <div id="products">
        <h1>{{configData.products.pageTitle}}</h1>
        <div id="productsListContainer">
            <div v-for="(product) in this.configData.products.list" :key="product.name" class="product">
                <img :src=product.imgURL @click="linkClicked($event)" :id="product.id" :route="configData.product.route + product.id">
                <h2 class="productName">{{product.name.toUpperCase()}}</h2>
                <p v-if="product.price" class="productPrice">{{product.price}} €</p>
                <p v-if="product.product_description==='Coming soon.'" class="productPrice">Coming soon.</p>
            </div>
        </div>
    </div>
</template>

<script>
    import {linkClicked} from '@/services/routingService';
    import {navbarToWhiteMod,displayNavbar} from "@/services/styleChanges";

    export default {
        name: "Products",
        props: ['configData'],
        mounted: function() {
            displayNavbar();
            navbarToWhiteMod();
        },
        methods: {
            linkClicked
        }
    }
</script>

<style scoped>
    @import "../style/products.css";
</style>
