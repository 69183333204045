<template>
    <FormulateForm @submit="sendEmail" id="contact" v-model="mail">
        <div id="contact-form">
            <h1 class="stepTitle">{{configData.contact.pageTitle}}</h1>
            <FormulateInput type="text" class="bigInput" name="mailAddress" label="Email address" validation="required"/>
            <FormulateInput name="mailArea" id="mail" class="bigInput" placeholder="Your message" type="textarea" validation="required"/>
            <div class="submit-section">
                <button type="submit" class="btn btn-secondary">Send mail</button>
            </div>
        </div>
    </FormulateForm>
</template>

<script>
    import {displayNavbar, navbarToWhiteMod} from "@/services/styleChanges";
    import {fetchURL} from "@/services/fetchURL";

    export default {
        props: ['configData'],
        name: "Contact",
        data() {
            return {
                sibApiKey: this.configData.credentials.sendinblue,
                mail: {},
            }
        },
        mounted: function() {
            displayNavbar();
            navbarToWhiteMod();
        },
        methods: {
            async sendEmail () {
                this.sibRequestURL = 'https://api.sendinblue.com/v3/smtp/email';
                this.sibRequestOptions = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'api-key': this.sibApiKey
                    },
                    body: JSON.stringify({
                        sender: {email: this.mail.mailAddress},
                        to: [{email: 'twotrainsleft@gmail.com'}],
                        textContent: this.mail.mailArea,
                        subject: 'WebSite Contact'
                    })
                };
                await fetchURL(this.sibRequestURL,this.sibRequestOptions);
                await this.$alert("click to return to the home page","Your email has been sent","success");
                this.$router.push('/');
            }
        },
    }
</script>

<style scoped>
    @import "../style/contact.css";
</style>
