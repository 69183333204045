import {countries} from "./gistfile1.json";
import {countryCodes} from "./countryCode.json"

export var configDataJS = {
  visuals: {
    logoWhite: require("../assets/img/logo2TL.png"),
    logoDark: require("../assets/img/logoBlack.png")
  },
  countries: countries,
  countryCodes: countryCodes,
  cart : {
    route: "/cart"
  },
  about : {
    route: "/about"
  },
  sales : {
    route: "/sales"
  },
  payment : {
    route: "/payment"
  },
  orderForm : {
    route: "/order-form"
  },
  product: {
    route: "/product/"
  },
  contact: {
    pageTitle: "CONTACT US",
    route: "/contact"
  },
  products: {
    pageTitle: "STORE",
    route: "/products",
    list: [
      {
        id: 1,
        name: "AS SAFE AS YESTERDAY EP",
        product_description: "AS SAFE AS YESTERDAY is Two Trains Left’s second EP." +
            "\nVery limited hand numbered marbled yellow vinyl variant (200)." +
            "\n\n100% Made in France."+
            "\n\nDelivery: Estimated 1 week after purchase." +
            "\n\nThis contains:" +
            "\n\n• AS SAFE AS YESTERDAY EP " +
            "\n\nTracklist" +
            "\n\n1. Everything Behind" +
            "\n2. All My Best" +
            "\n3. Wait On Me" +
            "\n4. Throw It All Away" +
            "\n5. Not Ok" +
            "\n6. Last Forever" +
            "\n7. Wrecked",
        imgURL: require("../assets/img/ASAY EP.png"),
        price: 25
      },
      {
        id: 2,
        name: "DEAD HAND T-SHIRT",
        product_description: "Light Pink T-Shirt featuring a purple left chest 2TL zombie hand print and the back is home to a printed oversized\n" +
            "dead-alive hand from the artwork of Two Trains Left’s new EP, ‘As Safe As Yesterday’.\n\n" +
            "100% Cotton\n\n" +
            "Delivery: Estimated 1 week after purchase.\n\n" +
            "Size chart:\n" +
            "S (Chest 34”-36”/86-91cm)\n" +
            "M (Chest 38”-40”/97-102cm)\n" +
            "L (Chest 42”-44”/107-122cm)\n" +
            "XL (Chest 46”-48”/117-122cm)",
        imgURL: require("../assets/img/PINK TEE.png"),
        price: 25,
        options: [
          {name:"S"},
          {name:"M"},
          {name:"L"},
          {name:"XL"}
        ]
      },
      {
        id: 3,
        name: "AS SAFE AS YESTERDAY T-SHIRT",
        product_description: "Black T-Shirt featuring a white left chest zombie hand print and the back is home to a printed part of the artwork\n" +
            "from Two Trains Left's new EP, ‘As Safe As Yesterday’.\n\n" +
            "100% Cotton\n\n" +
            "Delivery: Estimated 1 week after purchase.\n\n" +
            "Size chart:\n" +
            "S (Chest 34”-36”/86-91cm)\n" +
            "M (Chest 38”-40”/97-102cm)\n" +
            "L (Chest 42”-44”/107-122cm)\n" +
            "XL (Chest 46”-48”/117-122cm)",
        imgURL: require("../assets/img/BLACK TEE.png"),
        price: 25,
        options: [
          {name:"S"},
          {name:"M"},
          {name:"L"},
          {name:"XL"}
        ]
      },
      {
        id: 4,
        name: "AS SAFE AS YESTERDAY LONGSLEEVE",
        product_description: "Black Longsleeve featuring a white left chest zombie hand print and the back is home to a printed logo of Two\n" +
            "Trains Left's new EP, ‘As Safe As Yesterday’.\n\n" +
            "100% Cotton\n\n" +
            "Delivery: Estimated 1 week after purchase.\n\n" +
            "Size chart:\n" +
            "S (Chest 34”-36”/86-91cm)\n" +
            "M (Chest 38”-40”/97-102cm)\n" +
            "L (Chest 42”-44”/107-122cm)\n" +
            "XL (Chest 46”-48”/117-122cm)",
        imgURL: require("../assets/img/BLACK LONG.png"),
        price: 35,
        options: [
          {name:"S"},
          {name:"M"},
          {name:"L"},
          {name:"XL"}
        ]
      },
      {
        id: 5,
        name: "SURFING TIGER LONGLSEEVE",
        product_description: "Gold T-Shirt featuring a left chest 2TL tiger print and the back is home to a printed oversized tiger from the\n" +
            "artwork of Two Trains Left’s new EP, ‘As Safe As Yesterday’.\n\n" +
            "100% Cotton\n\n" +
            "Delivery: Estimated 1 week after purchase.\n\n" +
            "Size chart:\n" +
            "S (Chest 34”-36”/86-91cm)\n" +
            "M (Chest 38”-40”/97-102cm)\n" +
            "L (Chest 42”-44”/107-122cm)\n" +
            "XL (Chest 46”-48”/117-122cm)",
        imgURL: require("../assets/img/GOLD LONG.png"),
        price: 35,
        options: [
          {name:"S"},
          {name:"M"},
          {name:"L"},
          {name:"XL"}
        ]
      }

    ]
  },
  menuOption: {
    route: "/menu-options"
  },
  options: [
    {
      name: "HOME",
      route: "/"
    },
    {
      name: "STORE",
      route: "/products"
    },
    {
      name: "CONTACT",
      route: "/contact"
    },
  ],
}
