<template>
    <div id="cart">
        <img class="tigre" src="../assets/img/tigre.png">
        <ul class="item-list">
            <li v-for="(product,index) in clientData.articlesList" :key="index" class="item">
                <div class="center">
                    <img :src=product.product.imgURL>
                </div>
                <div class="center">
                    <h2 class="productName">{{product.product.name}}</h2>
                </div>
                <div class="center">
                    <p class="productOption">{{product.option}} </p>
                </div>
                <div class="center">
                    <p class="productPrice">{{product.product.price}} €</p>
                </div>
                <div class="center">
                    <select v-model="product.number" @change="updateNumbers" class="form-control form-control-lg item-number">
                        <option v-for="(num) in numbers" :key="num" :value="num">{{num}}</option>
                    </select>
                </div>
                <div class="center">
                    <div class="close-container" @click="deleteArticle(index)">
                        <div class="leftright"></div>
                        <div class="rightleft"></div>
                    </div>
                </div>
            </li>
        </ul>
        <div class="order-recap" v-if="this.clientData.articlesNumber >0">
            <h2>Total : {{this.clientData.articlesPrice}}€</h2>
            <button @click="linkClicked($event)" id="payment-button" :route="this.configData.orderForm.route" type="submit" class="btn btn-secondary">Order Now</button>
        </div>
    </div>
</template>

<script>
    import {linkClicked} from '@/services/routingService';
    import {displayNavbar, navbarToWhiteMod} from '@/services/styleChanges';
    export default {
        name: "Cart",
        props: ['clientData','configData'],
        data() {
            return {
               numbers: [1,2,3,4,5,6,7,8,9,10]
            }
        },
        mounted: function () {
            if(this.clientData.articlesNumber ===0) {
                this.$router.push("/");
                this.$alert("Your cart is empty","Warning","warning");
            }
            displayNavbar();
            navbarToWhiteMod();
        },
        methods: {
            linkClicked,
            updateNumbers() {
                this.$emit("updateNumbers");
            },
            deleteArticle(index) {
                this.$emit("deleteArticle",index);
                if(this.clientData.articlesNumber ===0) {
                    this.$router.push("/");
                    this.$alert("Your cart is empty","Warning","warning");
                }
            }
        }
    }
</script>

<style scoped>
    @import "../style/cart.css";
</style>
