<template>
    <div id="orderForm">
        <button @click="linkClicked" :route="this.configData.cart.route" class="clean-button center-element-row" id="backToCart">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                 x="0px" y="0px"
                 width="15px" height="15px"
                 viewBox="0 0 492 492" style="enable-background:new 0 0 492 492;" xml:space="preserve">
            <g>
                <g>
                    <path d="M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12
                        C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084
                        c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864
                        l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z"/>
                </g>
            </g>
            </svg>
            Back to cart
        </button>
        <ContactForm @nextStep="nextStep" :person-info="clientData.person" v-if="step===1"></ContactForm>
        <AddressForm @previousStep="previousStep" @goToPayment="goToPayment" :personAddress="clientData.address"
                     :config-data="configData" v-if="step===2"></AddressForm>
        <div id="recap" v-if="step===3">
            <div id="personInfos">
                <h2>DELIVERY ADDRESS</h2>
                <div id="personInfos-container">
                    <div>
                        <p>{{clientData.person.firstName}} {{clientData.person.lastName}}</p>
                        <p>{{clientData.address.address}}</p>
                        <p>{{clientData.address.city}}, {{clientData.address.country}}</p>
                        <p>{{clientData.address.zip}} | {{clientData.address.state}}</p>
                    </div>
                    <button id="modify-button" @click="step = 1">
                        Modify
                        <svg height="15" viewBox="0 0 325 325.37515" width="15" xmlns="http://www.w3.org/2000/svg">
                            <path d="m114.6875 284.675781-73.800781-73.800781 178.5-178.5 73.800781 73.800781zm-80.699219-60.800781 67.699219 67.699219-101.5 33.800781zm281.898438-140.300781-12.800781 12.800781-73.898438-73.898438 12.800781-12.800781c12.894531-12.902343 33.804688-12.902343 46.699219 0l27.199219 27.199219c12.800781 12.9375 12.800781 33.765625 0 46.699219zm0 0"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div id="order-recap">
                <h2>ORDER RECAP</h2>
                <ul class="item-list">
                    <li v-for="(product,index) in clientData.articlesList" :key="index" class="item-short">
                        <p class="productName">{{product.number}}x {{product.product.name}}</p>
                        <p class="productOption">{{product.option}}</p>
                        <p class="productPrice">{{product.product.price*product.number}} €</p>
                    </li>
                </ul>
                <p></p>
                <p>Transportation fees : {{clientData.transportFees}} €</p>
                <p id="total">TOTAL : {{clientData.articlesPrice + clientData.transportFees}} €</p>
            </div>
        </div>
        <Payment @paymentCompleted="paymentConfirmation" v-if="step===3" :client-data="clientData" :config-data="configData"></Payment>
    </div>

</template>

<script>
    import {hideNavbar} from "@/services/styleChanges";
    import {linkClicked} from "@/services/routingService";
    import {fetchURL} from "@/services/fetchURL";
    import ContactForm from "@/components/ContactForm";
    import AddressForm from "@/components/AddressForm";
    import Payment from "@/components/Payment";

    export default {
        name: "OrderForm",
        components: {Payment, AddressForm, ContactForm},
        props: ['clientData', 'configData'],
        data() {
            return {
                sibApiKey: process.env.VUE_APP_ENV_SENDINBLUE,
                sibRequestURL: "",
                sibRequestOptions: "",
                step: 1,
            }
        },
        mounted: function () {
            this.step = this.clientData.step;
            hideNavbar();
            if(this.clientData.articlesNumber ===0) {
                this.$router.push("/");
                this.$alert("Your cart is empty","Warning","warning");
            }
        },
        beforeDestroy: function () {
            this.$emit("memorizeStep", this.step);
        },
        methods: {
            linkClicked,
            nextStep(value) {
                this.step++;
                this.$emit("updatePerson", value);
            },
            previousStep(value) {
                this.step--;
                this.$emit("updateAddress", value);
            },
            goToPayment(value) {
                this.checkIfContactExist();
                this.step++;
                this.$emit("updateAddress", value);
            },
            async paymentConfirmation(order) {
                var httpResponse = 0;
                httpResponse = await this.checkIfContactExist();
                if (httpResponse === 404) {
                    httpResponse = await this.createContact();
                } else {
                    httpResponse = await this.updateContact();
                }
                switch (httpResponse) {
                    case 200: console.log("confirmation email has been sent");break;
                    case 201: console.log("contact added to sib"); break;
                    case 204: console.log("contact informations updated"); break;
                    default: console.log("an error has occured"); break;
                }
                httpResponse = await this.sendConfirmationEmail(order);
                if(httpResponse === 201) {
                    console.log("confirmation email has been sent");
                } else {
                    console.log("an error has occured");
                }
                await this.$alert("click to return to the home page", "Order completed", "success").then(  function () {
                    console.log("payment accepted");
                })
                this.$emit("paymentConfirmed");
                await this.$router.push('/');
            },
            checkIfContactExist() {
                this.sibRequestURL = 'https://api.sendinblue.com/v3/contacts/'+this.clientData.person.email;
                this.sibRequestOptions = {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'api-key': this.sibApiKey
                    }
                };
               return fetchURL(this.sibRequestURL,this.sibRequestOptions);
            },
            updateContact() {
                this.sibRequestURL = 'https://api.sendinblue.com/v3/contacts/'+this.clientData.person.email;
                this.sibRequestOptions = {
                    method: 'PUT',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'api-key': this.sibApiKey
                    },
                    body: JSON.stringify({
                        attributes: {
                            NOM: this.clientData.person.lastName,
                            PRENOM: this.clientData.person.firstName,
                            PHONE: this.clientData.person.phone,
                            COUNTRY: this.clientData.address.country,
                            STATE: this.clientData.address.state,
                            CITY: this.clientData.address.city,
                            ZIP: this.clientData.address.zip,
                            ADDRESS: this.clientData.address.address,
                            CPLADDRESS: this.clientData.address.cplAddress
                        },
                        listIds: [9]
                    })
                };
                return fetchURL(this.sibRequestURL,this.sibRequestOptions);
            },
            createContact() {
                this.sibRequestURL = 'https://api.sendinblue.com/v3/contacts';
                this.sibRequestOptions = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'api-key': this.sibApiKey
                    },
                    body: JSON.stringify({
                        attributes: {
                            NOM: this.clientData.person.lastName,
                            PRENOM: this.clientData.person.firstName,
                            PHONE: this.clientData.person.phone,
                            COUNTRY: this.clientData.address.country,
                            STATE: this.clientData.address.state,
                            CITY: this.clientData.address.city,
                            ZIP: this.clientData.address.zip,
                            ADDRESS: this.clientData.address.address,
                            CPLADDRESS: this.clientData.address.cplAddress
                        },
                        listIds: [9],
                        updateEnabled: false,
                        emailBlacklisted: false,
                        smsBlacklisted: false,
                        email: this.clientData.person.email
                    })
                };
                return fetchURL(this.sibRequestURL,this.sibRequestOptions);
            },
            sendConfirmationEmail (order) {
                this.sibRequestURL = 'https://api.sendinblue.com/v3/smtp/email';
                this.sibRequestOptions = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'api-key': this.sibApiKey
                    },
                    body: JSON.stringify({
                        sender: {name: 'Two Trains Left', email: 'twotrainsleft@gmail.com'},
                        to: [{email: this.clientData.person.email, name: this.clientData.person.firstName}],
                        params: {ORDER: order.id},
                        templateId: 9
                    })
                };
                return fetchURL(this.sibRequestURL,this.sibRequestOptions);
            },
        }
    }
</script>

<style scoped>
    @import "../style/orderForm.css";
    @import "../style/formStyle.css";
</style>
