<template>
    <div id="homePage">
        <div id="homeInfos">
            <h2 id="pageName">OFFICIAL WEBSITE</h2>
            <img id="logo_droit" alt="two trains left" :src="this.logo_droit"/>
            <div class="buttons">
                <button @click="goToStream"  target="_blank" type="button" id="stream" class="btn btn-outline-light">STREAM
                </button>
                <button @click="scrollToProducts" type="button" id="shopNow" class="btn btn-outline-light">SHOP
                </button>
                <button @click="goToWatch"  target="_blank" type="button" id="preSave" class="btn btn-outline-light">WATCH
                </button>
            </div>
        </div>
        <div id="backgroundImage" class="container-fluid">
        </div>
        <div id="backgroundVideo" class="container-fluid">
            <video class="background" muted loop autoplay playsinline>
                <source :src="this.video" type="video/mp4">
            </video>
        </div>
        <Products :config-data="configData"></Products>
    </div>
</template>

<script>
    import {linkClicked} from '@/services/routingService';
    import {navbarToWhiteMod, navbarToDefault,displayNavbar,fitProductsToHomePage} from "@/services/styleChanges";
    import Products from "@/components/Products";
    export default {
        name: "HomePage",
        components: {Products},
        props: ['configData'],
        data() {
            return {
                scrollPosition: 0,
                video: require("../assets/video/introduction_video.mp4"),
                logo_droit: require("../assets/img/logo_droit.png")
            }
        },
        created: function () {

        },
        mounted: function () {
            this.$emit('navConfig','full');
            window.addEventListener('scroll', this.updateScroll);
            displayNavbar();
            navbarToDefault();
            fitProductsToHomePage();
        },
        beforeDestroy: function() {
            window.removeEventListener('scroll', this.updateScroll);
        },
        methods: {
            linkClicked,
            updateScroll() {
                this.scrollPosition = window.scrollY/window.screen.height;
                if (this.scrollPosition > 0.12) {
                    document.getElementById('homeInfos').setAttribute('class',"fade");
                } else {
                    document.getElementById('homeInfos').setAttribute('class',"default");
                }
                if (this.scrollPosition > 0.74) {
                    navbarToWhiteMod();
                } else {
                    navbarToDefault();
                }
            },
            scrollToProducts () {
                window.scrollBy({
                    top: window.screen.height - 100 - window.scrollY,
                    left: 0,
                    behavior : "smooth"
                });
               // document.getElementById('products').scrollIntoView({behavior: "smooth"});
            },
            goToStream () {
                window.open("https://linktr.ee/twotrainsleft");
            },
            goToWatch () {
                window.open("https://www.youtube.com/@TwoTrainsLeft");
            }
        }
    }
</script>

<style scoped>
    @import "../style/homePage.css";
</style>
